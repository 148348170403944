import './scss/index.min.css';

import Bowser from 'bowser';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'core-js/features/promise';
import './polyfill'
// import reportWebVitals from './reportWebVitals';

import { Suspense } from 'react';
const PolyfillLoader = React.lazy(() => import('./PolyfillLoader'));
const App = React.lazy(() => import('./App'))

const browser = Bowser.getParser(window.navigator.userAgent);
const isModernBrowser = browser.satisfies({
  chrome: '>65',
  edge: '>80',
  firefox: '>69',
  opera: '>70',
});

const isWebkitBrowser = browser.satisfies({
  chrome: '>65',
  safari: '>10',
});
const isFirefox = browser.satisfies({
  firefox: '>65',
});

if (!isWebkitBrowser) {
  document.body.classList.add('no-webkit');
}
if (isFirefox) {
  document.body.classList.add('is-firefox');
}

ReactDOM.render(
  <Suspense fallback={null}>
    {/* if not normaly working browser do the polyfills */}
    {!isModernBrowser ? (
      <PolyfillLoader>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </PolyfillLoader>
    ) : (
      <App />
    )}
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
